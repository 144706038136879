import { API_URL } from '../utils/global';

const axiosDef = require('axios').default;

export const defaultErrorHandler = (axiosError) => {
  let error = null;
  if (axiosError.response && axiosError.response.data) {
    error = axiosError.response.data.errors
      ? axiosError.response.data.errors
      : axiosError.response.data.message
      ? axiosError.response.data.message
      : 'Error desconocido';
  } else {
    error = axiosError.message ? axiosError.message : 'Error desconocido';
  }

  if (axiosError.response && axiosError.response.status === 401) {
    sessionStorage.removeItem('auth');
    if (axiosError.config.url !== '/login') {
      window.location.reload();
    }
  }

  return Promise.reject(error);
};

export const defaultSuccesHandler = (response) => {
  return Promise.resolve(response);
};

export function createApi({
  baseURL = `${API_URL}`,
  timeout = 20000,
  headers = { 'Content-Type': 'application/json' },
  withCredentials = false,
  errorHandler = defaultErrorHandler,
  successHandler = defaultSuccesHandler,
  axios = axiosDef.create(),
}) {
  axios.defaults.baseURL = baseURL;
  axios.defaults.timeout = timeout;
  axios.defaults.headers = headers;
  axios.defaults.withCredentials = withCredentials;
  axios.defaults.validateStatus = (status) => {
    return status < 300;
  };
  axios.interceptors.response.use(
    (response) => successHandler(response),
    (error) => errorHandler(error)
  );
  axios.interceptors.request.use(
    (config) => {
      if (sessionStorage.auth) {
        const jwt = JSON.parse(sessionStorage.auth).data.access_token;
        if (jwt) {
          config.headers['Authorization'] = 'Bearer ' + jwt;
        }
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
  return axios;
}

export const inscripcionApi = createApi({
  baseURL: `${API_URL}/preinscripcion`,
});

export const cacheApi = createApi({
  baseURL: `${API_URL}/cache`,
});

export const personasApi = createApi({
  baseURL: `${API_URL}/personas`,
});
