import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import {
  Alert,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Spin,
  Typography,
} from 'antd';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { save, setPending } from '../redux/actions/inscripcionActions';
import { callStatus } from '../utils/constants';
import { API_URL } from '../utils/global';

const { TextArea } = Input;
const { Title } = Typography;

const Inscripcion = ({
  match: {
    params: { type },
  },
}) => {
  let history = useHistory();
  const dispatch = useDispatch();

  const stateDataObject = useSelector(({ inscripcion: { object } }) => object);
  const [anio, setAnio] = useState({ type: 0, name: '' });

  const [error, setError] = useState(false);
  const [errorSend, setErrorSend] = useState(false);
  const [progress, setProgress] = useState(false);
  const [conformidad, setConformidad] = useState(false);
  const [instituciones, setInstituciones] = useState([]);
  const [otraInstitucion, setOtraInstitucion] = useState(false);
  const [datos, setDatos] = useState(false);

  const [isDisabled, setIsDisabled] = useState(true);

  const [form] = Form.useForm();
  const formatDisplay = 'DD/MM/YYYY';

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const nextYear = year + 1;

    setAnio(
      type === '1' ? { type: type, name: year } : { type: type, name: nextYear }
    );
  }, [type]);

  useEffect(() => {
    let data = {};
    data['fecha'] = moment(moment(data.fecha));
    data['grado_cursada'] = undefined;

    if (stateDataObject.status === callStatus.OBJECT_SUCCESS) {
      data = cloneDeep(stateDataObject.data);
      data['fecha_nacimiento'] = moment(moment(data.fecha_nacimiento));
      data['alumno_id'] = data.id;

      data['grado_cursada'] =
        anio.type === 1 ? data.grado_cursada : data.grado_cursada + 1;

      setIsDisabled(false);
      setErrorSend(false);
      setProgress(false);
    }
    if (stateDataObject.status === callStatus.OBJECT_IN_PROCESS) {
      setErrorSend(false);
      setProgress(true);
    }
    if (stateDataObject.status === callStatus.OBJECT_FAILED) {
      setErrorSend(true);
      setProgress(false);
    }
    if (stateDataObject.status === callStatus.OBJECT_SAVE_SUCCESS) {
      setErrorSend(false);
      setProgress(false);

      form.resetFields();
      setConformidad(false);
      setDatos(false);
      history.push('/result');
    }

    form.setFieldsValue(data);
  }, [anio.type, dispatch, form, history, stateDataObject]);

  useEffect(() => {
    window.scrollTo(0, 0);

    setError(false);
    setProgress(true);

    fetch(`${API_URL}/personas/institucion`, {
      method: 'get',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => res.json())
      .then((result) => {
        setProgress(false);
        setInstituciones([...result]);
      })
      .catch((error) => {
        console.log(error);
        setProgress(false);
        setError(true);
      });

    // eslint-disable-next-line
  }, []);

  const onValuesChange = () => {
    if (
      form.getFieldsValue()['nombre'] &&
      form.getFieldsValue()['apellido'] &&
      form.getFieldsValue()['fecha_nacimiento'] &&
      form.getFieldsValue()['dni'] &&
      form.getFieldsValue()['familia_responsable'] &&
      form.getFieldsValue()['familia_direccion'] &&
      form.getFieldsValue()['familia_telefono']
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  const parseDateFromMoment = (val) => {
    let result = null;
    if (val) {
      const date = new Date(val);
      result = dayjs(date.toISOString()).format('YYYY-MM-DD');
    }
    return result;
  };

  const handleReset = () => {
    form.resetFields();
    setConformidad(false);
  };

  const handleConformidad = (e) => {
    setConformidad(e.target.checked);
  };

  const handleDatos = (e) => {
    setDatos(e.target.checked);
  };

  const onFinish = (values) => {
    values.fecha_nacimiento = parseDateFromMoment(values.fecha_nacimiento);

    values['turno_preferido_anio'] = anio.name;
    values['anio_cursada'] = anio.name;
    values['anio'] = anio.name;

    delete values.id;
    dispatch(save(values));
  };

  const onFinishFailed = (errorInfo) => {
    setErrorSend(false);
  };

  const handleOnChaneIntitucion = (value) => {
    if (value === 11) {
      setOtraInstitucion(true);
    }
  };

  return (
    <>
      <Spin spinning={progress}>
        <Title level={1}>{`Formulario de Inscripción ${anio.name}`}</Title>
        <Form
          form={form}
          layout="vertical"
          name="form"
          initialValues={{}}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onValuesChange}
        >
          <Row gutter={[8, 0]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Title level={3}>Datos del Alumno</Title>
            </Col>
          </Row>
          <Row gutter={[8, 0]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                name="alumno_id"
                label="alumno_id"
                style={{ display: 'none' }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                name="familia_id"
                label="familia_id"
                style={{ display: 'none' }}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 0]}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                name="nombre"
                label="Nombre"
                rules={[
                  {
                    required: true,
                    message: 'Requerido',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                name="apellido"
                label="Apellido"
                rules={[
                  {
                    required: true,
                    message: 'Requerido',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[8, 0]}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                name="dni"
                label="DNI"
                rules={[
                  {
                    required: true,
                    message: 'Requerido',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                name="fecha_nacimiento"
                label="Fecha Nacimiento"
                rules={[
                  {
                    required: true,
                    message: 'Requerido',
                  },
                ]}
              >
                <DatePicker style={{ width: '100%' }} format={formatDisplay} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[8, 0]}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item name="telefono" label="Teléfono">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item name="email" label="Email">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 0]}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item name="direccion" label="Dirección">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 0]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Title level={3}>Datos del Responsable</Title>
            </Col>
          </Row>

          <Row gutter={[8, 0]}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                name="familia_responsable"
                label="Nombre y Apellido Padre/Madre/Tutor"
                rules={[
                  {
                    required: true,
                    message: 'Requerido',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                name="familia_direccion"
                label="Dirección"
                rules={[
                  {
                    required: true,
                    message: 'Requerido',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[8, 0]}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                name="familia_telefono"
                label="Teléfono"
                rules={[
                  {
                    required: true,
                    message: 'Requerido',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item name="familia_email" label="Email">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 0]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Title level={3}>Otros Datos</Title>
            </Col>
          </Row>

          <Row gutter={[8, 0]}>
            <Col xs={24} sm={7} md={7} lg={7} xl={7}>
              <Form.Item name="institucion_id" label="Colegio">
                <Select onChange={(e) => handleOnChaneIntitucion(e)}>
                  {instituciones.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {otraInstitucion && (
              <Col xs={24} sm={6} md={6} lg={6} xl={6}>
                <Form.Item name="institucion_otro" label="Nombre del Colegio">
                  <Input />
                </Form.Item>
              </Col>
            )}
            <Col xs={12} sm={5} md={5} lg={5} xl={5}>
              <Form.Item
                name="grado_cursada"
                label={
                  anio.type === 1
                    ? `Grado que cursa en ${anio.name}`
                    : `Grado que cursará en ${anio.name}`
                }
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>

            <Col xs={12} sm={6} md={6} lg={6} xl={6}>
              <Form.Item name="turno_id" label="Turno">
                <Radio.Group>
                  <Radio.Button value={1}>Mañana</Radio.Button>
                  <Radio.Button value={2}>Tarde</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[8, 0]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                name="turno_preferido_id"
                label={`Turno preferido para cursar en Lingual Home en ${anio.name}`}
              >
                <Radio.Group>
                  <Radio.Button value={1}>Mañana</Radio.Button>
                  <Radio.Button value={2}>Tarde Temprano</Radio.Button>
                  <Radio.Button value={3}>Media Tarde</Radio.Button>
                  <Radio.Button value={4}>Tardecita</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 0]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                name="observacion"
                label="Observaciones (sugerencias, horarios ocupados, enfermedades, alergias, tratamientos a considerar)"
              >
                <TextArea />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[8, 0]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div>
                <b>
                  Información MUY IMPORTANTE. Por favor, leer en detalle antes
                  de aceptar en conformidad.
                </b>
              </div>
              <Link
                to={`/ficha_conformidad_${anio.name}.pdf`}
                relative="path"
                target="_blank"
                disabled={isDisabled}
              >
                <b>Descargar Ficha de conformidad</b>
              </Link>
            </Col>
          </Row>

          <Row gutter={[8, 0]} style={{ marginTop: '10px' }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Checkbox onChange={handleConformidad} disabled={isDisabled}>
                He leido y acepto las condiciones propuestas por la institución
                en la "Ficha de conformidad"
              </Checkbox>
            </Col>
          </Row>
          {stateDataObject.status === callStatus.OBJECT_SUCCESS ? (
            <Row gutter={[8, 0]} style={{ marginTop: '10px' }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Checkbox onChange={handleDatos}>
                  He revisado los datos del alumno y actualizado los campos
                  correspondientes
                </Checkbox>
              </Col>
            </Row>
          ) : null}

          <Row gutter={[8, 0]} style={{ marginTop: '20px' }}>
            <Col xs={12} sm={6} md={4} lg={4} xl={4}>
              <Form.Item>
                <Button
                  type="primary"
                  style={{ width: '100%' }}
                  onClick={() => {
                    dispatch(setPending());
                    history.push(`/inscripcion`);
                  }}
                >
                  Volver
                </Button>
              </Form.Item>
            </Col>
            <Col xs={12} sm={6} md={4} lg={4} xl={4}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: '100%' }}
                  disabled={
                    !conformidad ||
                    (stateDataObject.status === callStatus.OBJECT_SUCCESS &&
                      !datos)
                  }
                >
                  Enviar
                </Button>
              </Form.Item>
            </Col>
            <Col xs={12} sm={6} md={4} lg={4} xl={4}>
              <Form.Item>
                <Button
                  onClick={(e) => handleReset()}
                  style={{ width: '100%' }}
                >
                  Limpiar
                </Button>
              </Form.Item>
            </Col>
          </Row>

          {errorSend && (
            <Row gutter={[8, 0]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Alert
                  message="Error"
                  description={
                    stateDataObject.status === callStatus.OBJECT_FAILED
                      ? stateDataObject.error
                      : 'Ha ocurrido un error, vuelva a Cargar la Página'
                  }
                  type="error"
                  showIcon
                />
              </Col>
            </Row>
          )}
          {error && (
            <Row gutter={[8, 0]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Alert
                  message="Error"
                  description="Ha ocurrido un error, vuelva a Cargar la Página"
                  type="error"
                  showIcon
                />
              </Col>
            </Row>
          )}
        </Form>
      </Spin>
    </>
  );
};

export default Inscripcion;
