import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import { inscripcionReducer, cacheReducer } from './dataReducers';

export const rootReducer = combineReducers({
  inscripcion: inscripcionReducer,
  cache: cacheReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
