import { Button, Col, Row, Typography } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

const { Title } = Typography;

const TextContainer = styled.div`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  font-family: 'BlissM';
  margin-bottom: 20px;
`;
const TitleContainer = styled.div`
  font-size: 22px;
  margin-bottom: 5px;
  font-family: 'Borel';
  font-weight: bold;
  background-color: #ffb339;
  border-radius: 3px;
  padding: 15px 5px 5px 5px;
  text-align: center;
`;

const BodyContainer = styled.div`
  text-align: justify;
`;

const Tilde = styled.span`
  color: #0b844e;
  font-weight: bold;
  font-size: 18px;
`;

const ButtonCustom = styled.div`
  width: 100%;
  font-size: 28px;
  padding: 2px;
  font-family: 'Borel';
  font-weight: bold;
  text-align: center;
  background-color: #1a65eb;
  padding-top: 15px;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s;
  color: white;
  &:hover {
    background-color: #4b83f0;
  }
`;

const Home = () => {
  const history = useHistory();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <div style={{ flex: 1 }}>
        <Row
          gutter={[10, 10]}
          style={{ height: '100%' }}
          justify="center"
          align="center"
        >
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{ marginTop: '30px' }}
          >
            <Title
              style={{
                textAlign: 'center',
                color: '#007336',
                fontFamily: 'Borel',
              }}
              level={1}
            >
              Aprendé inglés en Lingual Home y asegurate un futuro de
              oportunidades
            </Title>
          </Col>
          <Col xs={24} sm={20} md={18} lg={18} xl={18}>
            <div
              style={{ width: '100%', padding: '10px', textAlign: 'center' }}
            >
              <img
                style={{ width: '70%' }}
                src={'/logo_new_verde2.png'}
                alt="Logo"
              />
            </div>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{ marginTop: '30px' }}
          >
            <Title
              style={{
                textAlign: 'center',
                color: '#007336',
                fontFamily: 'Borel',
              }}
              level={2}
            >
              Descubrí todo lo que ofrecemos
            </Title>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div
              style={{
                width: '100%',
                padding: '10px',
                fontSize: '16px',
                fontFamily: 'BlissM',
                marginBottom: '10px',
              }}
            >
              <TextContainer>
                <TitleContainer>Cursos</TitleContainer>
                <BodyContainer>
                  <div style={{ marginBottom: '8px' }}>
                    Ofrecemos cursos de inglés a medida presenciales para todas
                    las edades y niveles; y virtuales (para alumnos de la zona).
                  </div>
                  <div>
                    <Tilde>✓</Tilde> Desarrollá las cuatro habilidades
                    lingüísticas: hablar,escuchar, leer y escribir.
                  </div>
                  <div>
                    <Tilde>✓</Tilde> Preparate para los exámenes de Cambridge y
                    obtené una certificación internacional.
                  </div>
                  <div>
                    <Tilde>✓</Tilde> Participá en nuestra Semana de la Lectura
                    Extensiva y descubrí historias divertidas en inglés.
                  </div>
                  <div>
                    <Tilde>✓</Tilde> Mantené tu inglés fluido integrándote a
                    encuentros de conversación para adultos.
                  </div>
                </BodyContainer>
              </TextContainer>
              <TextContainer>
                <TitleContainer>Viajes y Programas</TitleContainer>
                <BodyContainer>
                  <div style={{ marginBottom: '8px' }}>
                    Organizamos experiencias inmersivas donde practicar el
                    inglés en un entorno real y entretenido.
                  </div>
                  <div>
                    <Tilde>✓</Tilde> Explorá espacios de interés locales en
                    nuestras salidas educativas grupales.
                  </div>
                  <div>
                    <Tilde>✓</Tilde> Sumergite al inglés con Programas de
                    Inmersión al Idioma con coordinadores nativos.
                  </div>
                  <div>
                    <Tilde>✓</Tilde> Embarcate en viajes educativos y
                    recreativos en inglés a lo largo del país.
                  </div>
                  <div>
                    <Tilde>✓</Tilde> Explorá las Islas Británicas con el
                    programa de Tutoría de la empresa Together.
                  </div>
                </BodyContainer>
              </TextContainer>
              <TextContainer>
                <TitleContainer>Desarrollo Profesional</TitleContainer>
                <BodyContainer>
                  <div style={{ marginBottom: '8px' }}>
                    Diseñamos talleres de capacitación para educadores y futuros
                    profesores de inglés, virtuales y presenciales.
                  </div>
                  <div>
                    <Tilde>✓</Tilde> Mejorá y actualizá tus habilidades
                    profesionales en un ambiente agradable.
                  </div>
                  <div>
                    <Tilde>✓</Tilde> Incrementá tus recursos pedagógicos y
                    didácticos.
                  </div>
                  <div>
                    <Tilde>✓</Tilde> Ampliá tus herramientas tecnológicas
                    aplicadas a la educación.
                  </div>
                </BodyContainer>
              </TextContainer>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Title
              style={{
                textAlign: 'center',
                color: '#007336',
                fontFamily: 'Borel',
              }}
              level={3}
            >
              Vení y formá parte de nuestra comunidad de aprendizaje en inglés
            </Title>
          </Col>
        </Row>
      </div>
      <Row gutter={[10, 10]} justify="center">
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          {/* <Button
            style={{ width: '100%', fontSize: '28px', padding: 2 }}
            onClick={() => history.push(`/inscripcion`)}
            type="primary"
            size="large"
          >
            Inscríbite ahora!
          </Button> */}

          <ButtonCustom onClick={() => history.push(`/inscripcion`)}>
            <div>Inscribite ahora!</div>
          </ButtonCustom>
        </Col>
      </Row>
    </div>
  );
};
export default Home;
