import { inscripcionApi, personasApi } from '../../utils/api';
import {
  getObjectDataAction,
  createObjectDataPending,
  getListDataAction,
  saveObjectDataAction,
  deleteObjectDataAction,
  // createObjectDataInProcess,
  // createObjectDataSuccess,
  // createObjectDataFailed,
} from '../../utils/DataAction';

export const get = (dni, type) =>
  getObjectDataAction({
    dataName: 'INSCRIPCION',
    api: personasApi,
    relUrl: `getByDni/${dni}/${type}`,
  });

// export const get = (dni, anioType, anio) => {
//   return (dispatch) => {
//     dispatch(createObjectDataInProcess('INSCRIPCION')());
//     return personasApi
//       .get(`/getByDni/${dni}`)
//       .then((response) => {
//         dispatch(
//           createObjectDataSuccess('INSCRIPCION')({
//             ...response.data,
//             anioType: anioType,
//             anio: anio,
//           })
//         );
//       })
//       .catch((error) => {
//         dispatch(createObjectDataFailed('INSCRIPCION')(error));
//       });
//   };
// };

export const getAll = getListDataAction({
  dataName: 'INSCRIPCION',
  api: inscripcionApi,
  relUrl: '',
});

export const save = (data) =>
  saveObjectDataAction({
    dataName: 'INSCRIPCION',
    api: inscripcionApi,
    relUrl: '',
    data: data,
  });

export const deleted = (id) =>
  deleteObjectDataAction({
    dataName: 'INSCRIPCION',
    api: inscripcionApi,
    relUrl: id,
  });

export const setPending = createObjectDataPending('INSCRIPCION');
