import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, InputNumber, Modal, Row, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { get, setPending } from '../redux/actions/inscripcionActions';
import { callStatus } from '../utils/constants';
import styled from 'styled-components';

const { Title } = Typography;
const { confirm, error } = Modal;

const ButtonCustom = styled.div`
  width: 100%;
  font-size: 28px;
  padding: 2px;
  font-family: 'Borel';
  font-weight: bold;
  text-align: center;
  background-color: #1a65eb;
  padding-top: 15px;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s;
  color: white;
  &:hover {
    background-color: #4b83f0;
  }
`;

const Home = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const nextYear = year + 1;
  const history = useHistory();
  const stateDataObject = useSelector(({ inscripcion: { object } }) => object);

  const [valueAlumno, setValueAlumno] = useState(null);
  const onChangeAlumno = (value) => {
    setValueAlumno(value);
    setValueDni(null);
  };

  const [valueAnio, setValueAnio] = useState(
    month >= 1 && month <= 7 ? 1 : month === 12 ? 2 : null
  );

  const onChangeAnio = (value) => {
    setValueAnio(value);
  };

  const [valueDni, setValueDni] = useState(null);
  const onChangeDni = (value) => {
    setValueDni(value);
  };

  const [valueDniFamiliar, setValueDniFamiliar] = useState(null);
  const onChangeDniFamiliar = (value) => {
    setValueDniFamiliar(value);
  };

  const dispatch = useDispatch();

  const onContinuar = () => {
    if (!valueAnio) {
      handleError('Seleccione año de inscripción');
    } else if (!valueAlumno) {
      handleError('Seleccione si es alumno o no');
    } else if (valueAlumno === 1 && !valueDni) {
      handleError('Ingrese Dni');
    } else {
      if (valueAlumno === 1) {
        dispatch(get(valueDni, valueAlumno));
      } else if (valueAlumno === 2 && valueDniFamiliar) {
        dispatch(get(valueDniFamiliar, valueAlumno));
      } else {
        dispatch(setPending());
        history.push(`/inscripcion/${valueAnio}`);
      }
      // if (valueAlumno === 1) {
      //   dispatch(get(valueDni,valueDniFamiliar));
      // } else {
      //   dispatch(setPending());
      //   history.push(`/inscripcion/${valueAnio}`);
      // }
    }
  };

  const handleError = (message) => {
    error({
      title: message,
      onOk() {},
    });
  };

  useEffect(() => {
    if (stateDataObject.status === callStatus.OBJECT_SUCCESS) {
      if (valueAlumno === 1 && !stateDataObject.data.id) {
        confirm({
          title:
            'El Dni ingresado no corresponde a ningun alumno, desea continuar y cargar uno nuevo?',
          icon: <ExclamationCircleOutlined />,
          okText: 'Si',
          cancelText: 'No',
          autoFocusButton: 'cancel',
          onOk() {
            dispatch(setPending());
            history.push(`/inscripcion/${valueAnio}`);
          },
          onCancel() {},
        });
      } else {
        history.push(`/inscripcion/${valueAnio}`);
      }
    }

    if (stateDataObject.status === callStatus.OBJECT_FAILED) {
      handleError(stateDataObject.error);
    }
    // eslint-disable-next-line
  }, [dispatch, history, stateDataObject]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Spin spinning={stateDataObject.status === callStatus.OBJECT_IN_PROCESS}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <div style={{ flex: 1 }}>
          <Title
            style={{
              textAlign: 'center',
              marginTop: '20px',
              color: '#007336',
              fontFamily: 'Borel',
              marginBottom: '10px',
            }}
            level={2}
          >
            Te ayudaremos a completar tu Inscripción
          </Title>

          {month >= 8 && month <= 11 ? (
            <>
              <Title
                style={{ textAlign: 'center', marginTop: '40px' }}
                level={3}
              >
                Quiero inscribirme para cursar...
              </Title>
              <Row gutter={[10, 10]} justify="center">
                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                  <Button
                    style={{ width: '100%', height: '130px', fontSize: '2em' }}
                    onClick={() => onChangeAnio(1)}
                    type={valueAnio === 1 ? 'primary' : 'default'}
                  >
                    <div>Año actual</div>
                    <div>{year}</div>
                  </Button>
                </Col>
                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                  <Button
                    style={{ width: '100%', height: '130px', fontSize: '2em' }}
                    onClick={() => onChangeAnio(2)}
                    type={valueAnio === 2 ? 'primary' : 'default'}
                  >
                    <div>Proximo año</div>
                    <div>{nextYear}</div>
                  </Button>
                </Col>
              </Row>
            </>
          ) : null}

          {month === 12 ? (
            <Title style={{ textAlign: 'center', marginTop: '40px' }} level={3}>
              Inscripción para el proximo año {nextYear}
            </Title>
          ) : null}

          {month >= 1 && month <= 7 ? (
            <Title style={{ textAlign: 'center', marginTop: '40px' }} level={3}>
              Inscripción para el año actual {year}
            </Title>
          ) : null}

          <Title style={{ textAlign: 'center', marginTop: '40px' }} level={3}>
            Ya sos parte de Lingual Home?
          </Title>
          <Row gutter={[10, 10]} justify="center">
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Button
                style={{ width: '100%', height: '130px', fontSize: '2em' }}
                onClick={() => onChangeAlumno(1)}
                type={valueAlumno === 1 ? 'primary' : 'default'}
              >
                Soy alumno
              </Button>
            </Col>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Button
                style={{ width: '100%', height: '130px', fontSize: '2em' }}
                onClick={() => onChangeAlumno(2)}
                type={valueAlumno === 2 ? 'primary' : 'default'}
              >
                <div>Quiero ser</div>
                <div>alumno</div>
              </Button>
            </Col>
          </Row>
          <Row gutter={[10, 10]} justify="center">
            <Col
              xs={24}
              sm={16}
              md={16}
              lg={16}
              xl={16}
              style={{ textAlign: 'center', fontSize: '15px' }}
            >
              * Si es un alumno que dejo de venir a Lingual Home en el pasado,
              elija "Soy alumno" para restaurar sus datos anteriores.
            </Col>
          </Row>
          {valueAlumno === 1 ? (
            <>
              <Title
                style={{ textAlign: 'center', marginTop: '40px' }}
                level={3}
              >
                Ingresá tu DNI
              </Title>
              <Row gutter={[10, 10]} justify="center">
                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                  <InputNumber
                    style={{ width: '100%' }}
                    value={valueDni}
                    onChange={onChangeDni}
                    placeholder="DNI"
                  />
                </Col>
              </Row>
            </>
          ) : null}

          {valueAlumno === 2 ? (
            <>
              <Title
                style={{ textAlign: 'center', marginTop: '40px' }}
                level={3}
              >
                <div>
                  Pertenecés a un grupo familiar que ya es parte de Lingual
                  Home?
                </div>
                <div>Ingresá el DNI de alguno de los integrantes</div>
              </Title>
              <Row gutter={[10, 10]} justify="center">
                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                  <InputNumber
                    placeholder="DNI"
                    style={{ width: '100%' }}
                    value={valueDniFamiliar}
                    onChange={onChangeDniFamiliar}
                  />
                </Col>
              </Row>
            </>
          ) : null}
        </div>
        <Row gutter={[10, 10]} justify="center" style={{ marginTop: '40px' }}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            {/* <Button
              style={{ width: '100%' }}
              onClick={() => onContinuar()}
              type="primary"
            >
              Continuar
            </Button> */}

            <ButtonCustom onClick={() => onContinuar()}>
              <div>Continuar...</div>
            </ButtonCustom>
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

export default Home;
