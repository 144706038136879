import { Col, Layout, Row } from 'antd';
import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import routes from './routes';

const LayoutStyle = createGlobalStyle`

.ant-layout-header{
  background:#0b844e;
  height:auto;
  padding:0;
}

.ant-layout-content{
  background:white;
}


.ant-layout-footer{
  background:#0b844e;
  color:white;
}
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  height: 100px;
  justify-content: space-between;
  align-items: center;
`;

const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 80px;
  justify-content: center;
  align-items: center;
`;

const { Content, Header, Footer } = Layout;
const App = () => {
  const history = useHistory();

  return (
    <div>
      <LayoutStyle />
      <Layout style={{ height: '100%', background: 'white' }}>
        <Header
          style={{
            position: 'fixed',
            zIndex: 1,
            width: '100%',
          }}
        >
          <HeaderContainer>
            <div style={{ height: '75px', padding: '0px 5px 0px 5px' }}>
              <img
                style={{ height: '100%', cursor: 'pointer' }}
                src={'/logo_new_horizontal2.png'}
                alt="Logo"
                onClick={() => history.push(`/home`)}
              />
            </div>
            <div style={{ display: 'flex', padding: '10px' }}>
              <div
                style={{ padding: '5px', fontSize: '20px', fontWeight: 'bold' }}
              >
                <Link
                  style={{ color: 'white', fontFamily: 'Borel' }}
                  to={'/home'}
                >
                  Home
                </Link>
              </div>
              <div
                style={{ padding: '5px', fontSize: '20px', fontWeight: 'bold' }}
              >
                <Link
                  style={{ color: 'white', fontFamily: 'Borel' }}
                  to={'/inscripcion'}
                >
                  Inscripción
                </Link>
              </div>
              {/* <div style={{ padding: '5px', fontSize: '20px', fontWeight: 'bold' }}>
                <Link style={{ color: '#007336' }} to={'/contacto'}>
                  Contacto
                </Link>
              </div> */}
            </div>
          </HeaderContainer>
        </Header>
        <Content
          style={{
            padding: '15px',
            marginTop: '110px',
            background: 'white',
            minHeight: 500,
          }}
        >
          <Row
            gutter={[8, 0]}
            justify="center"
            align="center"
            style={{ height: '100%' }}
          >
            <Col xs={24} sm={24} md={18} lg={12} xl={12}>
              {routes}
            </Col>
          </Row>
        </Content>
        <Footer>
          <FooterContainer>
            <div style={{ textAlign: 'center' }}>
              Derechos Reservados © LingualHome 2024
            </div>
            <div style={{ textAlign: 'center' }}>
              Avellaneda 905 Trenque Lauquen 6400
            </div>
            <div style={{ textAlign: 'center' }}>
              Te: 2392 - 505653 / 570726
            </div>
            <div style={{ textAlign: 'center' }}>powered by THEZA</div>
          </FooterContainer>
        </Footer>
      </Layout>
    </div>
  );
};

export default App;
