import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Contacto from '../components/Contacto';
import Home from '../components/Home';
import Inicio from '../components/Inicio';
import Inscripcion from '../components/Inscripcion';
import ResultMessage from '../components/ResultMessage';

const routes = (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/inscripcion" component={Inicio} />
    <Route path="/home" component={Home} />
    <Route path="/inscripcion/:type" component={Inscripcion} />
    <Route path="/contacto" component={Contacto} />
    <Route path="/result" component={ResultMessage} />
  </Switch>
);

export default routes;
