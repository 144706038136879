import React from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
const ResultMessage = () => {
  return (
    <Result
      status="success"
      title="Su solicitud se ha enviado con éxito"
      subTitle="Por favor comunicarse con secretaría para realizar el pago. Muchas gracias!"
      extra={[
        <Link to={'/'}>
          <Button type="primary" key="console">
            Volver al Inicio
          </Button>
        </Link>,
      ]}
    />
  );
};
export default ResultMessage;
